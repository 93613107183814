import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"

import Gallery from "../components/gallery.js"
import CategoryLink from "../components/catLink.js";

class AllProjects extends React.Component {
  render() {
    var design = this.props.design
    var architecture = this.props.architecture
    var products = this.props.products

    var allProjects = design.concat(architecture).concat(products)

    var mapAcs = allProjects.sort((a, b) => {
      const delta =
        new Date(b.node.date).getTime() - new Date(a.node.date).getTime()
      return delta
    })
    return (
      <main>
        {mapAcs.map((post, key) => (
          <section key={key}>
            <div className="mobile">
              <h1 className="mainTitle">{post.node.title}</h1>
              <CategoryLink link={post.node.acf.category_main_link_slug} name={post.node.acf.category_main_link_name}></CategoryLink>
            </div>
            <Gallery images={post.node.acf.gallery}>
              <div className="content">
                <h1 className="mainTitle screen">{post.node.title}</h1>
                {post.node.acf.category_main_link_slug ? (
                 <CategoryLink link={post.node.acf.category_main_link_slug} name={post.node.acf.category_main_link_name}></CategoryLink>
              ) : (
                ``
              )}
                <div
                  dangerouslySetInnerHTML={{ __html: post.node.acf.content }}
                />
              </div>
            </Gallery>
          </section>
        ))}
      </main>
    )
  }
}

const CatPage = ({ data }) => (
  <Layout>
    <SEO title="Categories Projects" />
    <AllProjects
      architecture={data.allWordpressWpArchitecture.edges}
      design={data.allWordpressWpDesign.edges}
      products={data.allWordpressWpProducts.edges}
    ></AllProjects>
    <div>
      <p>&nbsp;</p>
    </div>
  </Layout>
)

export default CatPage

export const query = graphql`
  query($slug: String!) {
    allWordpressWpDesign(
      filter: { categories: { elemMatch: { slug: { eq: $slug } } } }, sort: {fields: date, order: DESC}
    ) {
      edges {
        node {
          slug
          title
          date
          acf {
            content
            category_main_link_name
            category_main_link_slug
            gallery {
              localFile {
                childImageSharp {
                  # Try editing the "maxWidth" value to generate resized images.
                  fluid(maxWidth: 1800, srcSetBreakpoints: [600, 1200, 1800]) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
          }
        }
      }
    }
    allWordpressWpArchitecture(
      filter: { categories: { elemMatch: { slug: { eq: $slug } } } }, sort: {fields: date, order: DESC}
    ) {
      edges {
        node {
          slug
          title
          date
          acf {
            content
            category_main_link_name
            category_main_link_slug
            gallery {
              localFile {
                childImageSharp {
                  # Try editing the "maxWidth" value to generate resized images.
                  fluid(maxWidth: 1800, srcSetBreakpoints: [600, 1200, 1800]) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
          }
        }
      }
    }
    allWordpressWpProducts(
      filter: { categories: { elemMatch: { slug: { eq: $slug } } } },sort: {fields: date, order: DESC}
    ) {
      edges {
        node {
          slug
          title
          menu_order
          date
          acf {
            content   
            category_main_link_name
            category_main_link_slug
            gallery {
              localFile {
                childImageSharp {
                  # Try editing the "maxWidth" value to generate resized images.
                  fluid(maxWidth: 1800, srcSetBreakpoints: [600, 1200, 1800]) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`
