import React from "react"

export default function CategoryLink(props) {
    var segment_str = typeof window !== 'undefined' ? window.location.href : '';
    var segment_array = segment_str.split('/')
    var last_segment = segment_array.pop()


    var link = (props.link?props.link:'').toLowerCase().normalize("NFC").replace(/\u00fc/g, "ue").replace(/\u00e4/g, "ae").replace(/\u00f6/g, "oe");
    var name = props.name
    var linkname = '/'+link 
    return <div className="categoryLink"><a href={link === last_segment ? '' : linkname }>{name}</a>
            </div> 
    }
  